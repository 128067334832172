import { action, observable } from "mobx";
import { Agency } from "../models";
import { deepSearch } from "../services/util.service";

export class AgencyStore {
    @observable.deep agencys: Agency[] = [] as Agency[];
    @observable focusedAgency: Agency = {} as Agency;

    @action setAgencys(agencys: Agency[]) {
        this.agencys = agencys;
    }

    @action updateAgency(agency: Agency) {
        const index = this.agencys.findIndex(c => c._id === agency._id);
        if (index > -1) {
            this.agencys[index] = agency;
        }
        this.setFocusedAgency(agency);
    }

    @action addAgency(agency: Agency) {
        this.agencys.push(agency);
    }

    @action getAgencyById(id: string): Agency | undefined {
        return this.agencys.find(c => c._id === id);
    }

    @action setFocusedAgency(agency: Agency) {
        this.focusedAgency = agency;
    }

    @action filterByName(filter: string) {
        return this.agencys.filter(agency => deepSearch(agency.name, filter));
    }

    @action clearCurrentSession() {
        this.agencys = [];
        this.focusedAgency = {} as Agency;
    }
}
