import React, { useState } from "react";
import { Button, Dialog, TextInputField } from "evergreen-ui";
import { Formik, FormikHelpers } from "formik";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { performLogin } from "../services/login.service";
import { LoginRequest } from "../models";
import { forgotPassword, getProfileDetails } from "../services/profile.service";
import { validateCNPJ } from "../services/util.service";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

interface LoginProps extends Stores {
    onSuccess: () => void;
}

export const Login = inject("rootStore")(
    observer((props: LoginProps) => {
        const { t } = useTranslation();
        const { loadingStore, routingStore, sessionStore } = props.rootStore!;
        const [showForgotPassword, setForgotPassword] = useState(false);
        const [, setEmailSet] = useState("");

        const onSubmit = async (values: LoginRequest, { setErrors }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            try {
                try {
                    const response = await performLogin(values);
                    if (response.token && response) {
                        const profile = await getProfileDetails(response);
                        if (profile) {
                            sessionStore.setCurrentSession({ ...response, profile });
                            routingStore.push("/");
                            props.onSuccess();
                        } else {
                            setErrors({
                                username: t("form.errors.invalid")
                            });
                        }
                    } else {
                        setErrors({
                            username: t("form.errors.invalid")
                        });
                    }
                } catch (e) {
                    let message;
                    switch (e.status) {
                        case 401:
                            message = "Senha incorreta para o CNPJ informado";
                            break;
                        case 404:
                            message = "CNPJ não cadastrado no sistema";
                            break;
                    }

                    setErrors({
                        username: message
                    });
                }
                loadingStore.stopLoading();
            } catch (e) {
                setErrors({
                    username: t("form.errors.user_not_found")
                });
                loadingStore.stopLoading();
            }
        };

        const onSubmitForgot = async (values: any, { resetForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            setForgotPassword(false);
            forgotPassword(values.cnpj)
                .then(result => {
                    loadingStore.stopLoading();
                    if (result.email) {
                        setForgotPassword(true);
                        setEmailSet(result.email);
                        toast.success(t("success.email_sent"));
                        resetForm();
                    } else {
                        setForgotPassword(true);
                        toast.error(t("error.sending_email"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    setForgotPassword(true);
                    toast.error(t("error.sending_email"));
                });
        };

        return (
            <>
                <Dialog
                    isShown={showForgotPassword}
                    title={t("form.login.forgotPassword")}
                    onCloseComplete={() => setForgotPassword(false)}
                    hasFooter={false}
                >
                    <Formik
                        initialValues={{
                            cnpj: ""
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (!values.cnpj) {
                                errors.cnpj = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmitForgot}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-table">
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            {/*{emailSent ? (*/}
                                            {/*    <span>*/}
                                            {/*        {t("form.login.emailSent")}*/}
                                            {/*        <br />*/}
                                            {/*        {emailSent}*/}
                                            {/*    </span>*/}
                                            {/*) : (*/}
                                            {/*    <InputMask*/}
                                            {/*        mask="99.999.999/9999-99"*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*        onBlur={handleBlur}*/}
                                            {/*        value={values && values.cnpj}*/}
                                            {/*    >*/}
                                            {/*        {(inputProps: any) => (*/}
                                            {/*            <TextInputField*/}
                                            {/*                {...inputProps}*/}
                                            {/*                label="CNPJ:"*/}
                                            {/*                name="cnpj"*/}
                                            {/*                isInvalid={!!errors.cnpj}*/}
                                            {/*                validationMessage={errors.cnpj}*/}
                                            {/*            />*/}
                                            {/*        )}*/}
                                            {/*    </InputMask>*/}
                                            {/*)}*/}
                                            <div>
                                                Favor enviar sua solicitação de nova senha para o email{" "}
                                                <a href="mailto:agentesbrasil@hudd.com.br">agentesbrasil@hudd.com.br</a>
                                                . Por questões de segurança, o email deve ser enviado a partir do email
                                                que está cadastrado como contato no seu cadastro.
                                            </div>
                                        </div>
                                    </div>
                                    {/*{!emailSent && (*/}
                                    {/*    <div className="dialog-footer">*/}
                                    {/*        <Button type="submit" appearance="primary" className="primary">*/}
                                    {/*            {t("proceed")}*/}
                                    {/*        </Button>*/}
                                    {/*    </div>*/}
                                    {/*)}*/}
                                </div>
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <Formik
                    initialValues={{
                        username: "",
                        password: ""
                    }}
                    validate={values => {
                        // same as above, but feel free to move this into a class method now.
                        let errors: Partial<LoginRequest> = {};
                        if (!values.username) {
                            errors.username = t("form.errors.required");
                        } else if (!validateCNPJ(values.username)) {
                            errors.username = t("form.errors.invalid");
                        }
                        if (!values.password) {
                            errors.password = t("form.errors.required");
                        }
                        return errors;
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="login">
                                <InputMask
                                    mask="99.999.999/9999-99"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.username}
                                >
                                    {(inputProps: any) => (
                                        <TextInputField
                                            {...inputProps}
                                            name="username"
                                            label="CNPJ:"
                                            isInvalid={!!errors.username}
                                            validationMessage={errors.username}
                                        />
                                    )}
                                </InputMask>
                                <TextInputField
                                    type="password"
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.password}
                                    label="Senha:"
                                    isInvalid={!!errors.password}
                                    validationMessage={errors.password}
                                />
                                <div>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a
                                        href="#"
                                        onClick={() => {
                                            setForgotPassword(true);
                                            setEmailSet("");
                                        }}
                                    >
                                        Esqueci minha senha
                                    </a>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        appearance="primary"
                                        className="primary"
                                        justifyContent="center"
                                    >
                                        Entrar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </>
        );
    })
);
