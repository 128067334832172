export const config = {
    // url: "http://agentesbrasil.com.br/backend/api/v1",
    // url: "/backend/api/v1",
    // host: "http://agentesbrasil.com.br/backend/",
    // host: "/backend/",
    url: `${window.location.protocol}//${window.location.hostname}/backend/api/v1`,
    host: `${window.location.protocol}//${window.location.hostname}/backend/`,
    color: "#000"
};

export const states = [
    {
        code: "AC",
        name: "Acre"
    },
    {
        code: "AL",
        name: "Alagoas"
    },
    {
        code: "AM",
        name: "Amazonas"
    },
    {
        code: "AP",
        name: "Amapá"
    },
    {
        code: "BA",
        name: "Bahia"
    },
    {
        code: "CE",
        name: "Ceará"
    },
    {
        code: "DF",
        name: "Distrito Federal"
    },
    {
        code: "ES",
        name: "Espírito Santo"
    },
    {
        code: "GO",
        name: "Goiás"
    },
    {
        code: "MA",
        name: "Maranhão"
    },
    {
        code: "MG",
        name: "Minas Gerais"
    },
    {
        code: "MS",
        name: "Mato Grosso do Sul"
    },
    {
        code: "MT",
        name: "Mato Grosso"
    },
    {
        code: "PA",
        name: "Pará"
    },
    {
        code: "PB",
        name: "Paraíba"
    },
    {
        code: "PE",
        name: "Pernambuco"
    },
    {
        code: "PI",
        name: "Piauí"
    },
    {
        code: "PR",
        name: "Paraná"
    },
    {
        code: "RJ",
        name: "Rio de Janeiro"
    },
    {
        code: "RN",
        name: "Rio Grande do Norte"
    },
    {
        code: "RO",
        name: "Rondônia"
    },
    {
        code: "RR",
        name: "Roraima"
    },
    {
        code: "RS",
        name: "Rio Grande do Sul"
    },
    {
        code: "SC",
        name: "Santa Catarina"
    },
    {
        code: "SE",
        name: "Sergipe"
    },
    {
        code: "SP",
        name: "São Paulo"
    },
    {
        code: "TO",
        name: "Tocantins"
    }
];
