import removeAccents from "remove-accents";

export const comparator = function (obj: any, text: any): boolean {
    if (obj && text && typeof obj === "object" && typeof text === "object") {
        for (const objKey in obj) {
            if (objKey.charAt(0) !== "$" && objKey.hasOwnProperty(obj) && comparator(obj[objKey], text[objKey])) {
                return true;
            }
        }
        return false;
    }
    text = removeAccents(("" + text).toLowerCase());
    return ("" + removeAccents(obj)).toLowerCase().indexOf(text) > -1;
};

export const deepSearch = (obj: any, text: any): boolean => {
    if (typeof text == "string" && text.charAt(0) === "!") {
        return !deepSearch(obj, text.substr(1));
    }
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            if (deepSearch(obj[i], text)) {
                return true;
            }
        }
        return false;
    }
    switch (typeof obj) {
        case "boolean":
        case "number":
        case "string":
            return comparator(obj, text);
        case "object":
            switch (typeof text) {
                case "object":
                    return comparator(obj, text);
                default:
                    for (let objKey in obj) {
                        if (objKey.charAt(0) !== "$" && deepSearch(obj[objKey], text)) {
                            return true;
                        }
                    }
                    break;
            }
            return false;
        default:
            return false;
    }
};

export function getNamePlaceholder(name = ""): string {
    const names = name.split(" ");
    return names.map(n => n.charAt(0)).join("");
}

export function setTime(date: Date, hours: number, minutes: number, seconds: number): Date {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function setDate(day: number, month: number, year: number): Date {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

export function setDateTime(
    day: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    seconds: number
): Date {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function calculateAge(birth: Date) {
    const diff = Date.now() - birth.getTime();
    const ageDiff = new Date(diff);

    return Math.abs(ageDiff.getUTCFullYear() - 1970);
}

export function validateCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj === "") return false;

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
    )
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    return resultado === Number(digitos.charAt(1));
}

export function validateCPF(fiscalCodeToValidate: string): boolean {
    let sum;
    let remaining;
    sum = 0;
    const fiscalCode = fiscalCodeToValidate.replace(/\D/g, "");
    if (fiscalCode.length !== 11 || !Array.from(fiscalCode).filter(e => e !== fiscalCode[0]).length) {
        return false;
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(fiscalCode.substring(i - 1, i)) * (11 - i);
    }
    remaining = (sum * 10) % 11;

    if (remaining === 10 || remaining === 11) remaining = 0;
    if (remaining !== parseInt(fiscalCode.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(fiscalCode.substring(i - 1, i)) * (12 - i);
    }
    remaining = (sum * 10) % 11;

    if (remaining === 10 || remaining === 11) {
        remaining = 0;
    }
    return remaining === parseInt(fiscalCode.substring(10, 11));
}
