import { Entity, EntityWithId, Role } from "./generic";

export interface Profile extends Entity, EntityWithId {
    role: Role;
    password: string;
    cnpj: string;
    name: string;
    picture: string;
    city: string;
    state: string;
    email: string;
    website: string;
    responsible: string;
    responsibleDocument: string;
    contactInfo: string;
    lastRenew?: Date;
    subscriptionExpiry?: Date;
    shouldChangePassword?: boolean;
    status: StatusEnum;
    termsAgreedAt?: Date;
}

export interface UpdatePasswordRequest {
    password: string;
}

export enum StatusEnum {
    INACTIVE = 0,
    ACTIVE = 1,
    EXPIRED = 2
}

export interface Artist extends Entity, EntityWithId {
    picture: string;
    artisticName: string;
    website: string;
    office: string; //UUID
}

export declare type Agency = Omit<
    Profile,
    | "role"
    | "password"
    | "cnpj"
    | "responsibleDocument"
    | "lastRenew"
    | "subscriptionExpiry"
    | "shouldChangePassword"
    | "status"
    | "termsAgreedAt"
>;

export interface ProfileUpdateRequest {
    lastRenew: Date;
    subscriptionExpiry: Date;
    password?: string;
    active: boolean;
}

export interface NewProfileRequest {
    name: string;
    cnpj: string;
    lastRenew: Date;
    subscriptionExpiry: Date;
    password: string;
    active: boolean;
}
