import React, { ReactElement } from "react";

export function Etica(): ReactElement {
    return (
        <div className="card">
            <h3>Código de Ética</h3>
            <ol>
                <li>
                    Agências de talentos poderão atuar somente no agenciamento ou representação artísticos, nunca na
                    produção de elenco, seja direta ou indiretamente.
                </li>
                <li>
                    As agências associadas se comprometem a apenas aceitar e/ou negociar trabalhos com prazo máximo de
                    pagamento de até 60 dias a partir da data da prestação de serviços, salvo em raríssimos casos em que
                    pondere o bom senso.
                </li>
                <li>
                    Cláusulas contratuais abusivas ou unilaterais devem ser informadas ao grupo para que todos
                    mantenham-se alinhados no mesmo posicionamento e as conquistas sejam coletivas e estendidas a todos.
                </li>
                <li>
                    Para novos agenciados, sugerimos que as Agências passem a praticar, como praxe, a taxa de 20% para
                    trabalhos não mensais (filmes, séries, diárias); e entre 10% a 20% mensais sobre contratos de prazo
                    longo ou por obra certa.
                </li>
                <li>É proibido divulgar trabalhos inexistentes com o intuito de captar elenco.</li>
                <li>
                    Quando um talento estiver sob exclusividade de uma agência, todo e qualquer contato de trabalho
                    deverá ser feito com a agência e jamais diretamente com o agenciado.
                </li>
                <li>
                    Quando um talento agir de forma antiética (i.e. não repassando o cachê devido, acionando a justiça
                    contra a agência etc), a agência deverá dar ciência aos demais agentes.
                    <p>Para os casos de troca de agências:</p>
                    <ol>
                        <li>
                            SÉRIES / OBRAS CERTAS
                            <ol>
                                <li>
                                    Quando o TALENTO trocar de agente entre temporadas:
                                    <ol type="i">
                                        <li>
                                            A Agência nova negocia tudo, a Agência antiga vira ANUENTE, e dividem
                                            comissão;
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            PRAZO LONGO
                            <ol>
                                <li>
                                    Quando o talento trocar de agente com um contrato de prazo longo ainda em vigência:
                                    <ol type="i">
                                        <li>
                                            A Agência antiga permanece sendo remunerada pelo TALENTO até o vencimento do
                                            contrato sobre os itens acordados entre as partes (merchandising, luvas,
                                            salário mensal, período de extensão automática etc);
                                        </li>
                                        <li>O TALENTO fica desobrigado de pagar à nova Agência qualquer valor.</li>
                                        <li>
                                            Findo o contrato, sua renovação passa a ser responsabilidade da nova
                                            Agência;
                                        </li>
                                        <li>
                                            No caso de campanhas ou ações publicitárias propostas pela emissora
                                            contratante, caberá ao TALENTO decidir se estas serão conduzidas pela antiga
                                            ou nova Agência.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    Quando a troca de escritório coincidir com a renovação contratual:
                                    <ol type="i">
                                        <li>a nova Agência assume a negociação;</li>
                                        <li>
                                            a Agência que originou o contrato não terá direito a qualquer remuneração.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            FILMES
                            <ol>
                                <li>
                                    Quando um filme tiver sua sequência anunciada após o TALENTO já ter trocado de
                                    agente:
                                    <ol type="i">
                                        <li>A nova Agência assume a negociação e as obrigações contratuais;</li>
                                        <li>A antiga Agência entra no contrato como ANUENTE;</li>
                                        <li>Ambas as agências dividem a comissão.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            PUBLICIDADE
                            <ol>
                                <li>
                                    NOVO CONTRATO COM CLIENTE ANTIGO - Quando um antigo cliente desejar fazer uma nova
                                    campanha publicitária com o TALENTO:
                                    <ol type="i">
                                        <li>A nova Agência assume a negociação</li>
                                        <li>
                                            A Agência que originou o contrato não terá direito a qualquer remuneração e
                                            se compromete a informar ao cliente a mudança de escritório do TALENTO.
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    RENOVAÇÃO AUTOMÁTICA DE CONTRATO:
                                    <ol type="i">
                                        <li>
                                            Em caso de contratos com renovação automática ou que tenha previsto em
                                            cláusula a renovação via Interveniente, a comissão será paga somente à
                                            Agência que o originou.
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>
                            OUTROS CONTRATOS
                            <ol>
                                <li>
                                    O antigo agente terá um prazo de até TRÊS MESES após o desligamento do TALENTO para
                                    continuar negociando contratos pendentes. Após este período, a negociação deverá ser
                                    repassada à nova Agência.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    Sempre prezar pelo bom relacionamento entre as agências. Caso ocorram casos que coloquem em dúvida a
                    reputação ou que desabonem alguma agência ou agenciado, algum representante do grupo deverá
                    contatá-los para elucidar o ocorrido. A ideia é evitar que rumores ou mal-entendidos se propaguem,
                    contaminando o grupo.
                </li>
                <li>Respeitar os pisos de categoria instituídos pelo SATED.</li>
                <li>
                    Envidar os melhores esforços para que diária de viagem, diária de standby, diária parada, diária de
                    prova de roupa e maquiagem, diária de preparação, diária de ensaio sejam remuneradas.
                </li>
                <li>
                    Não aceitar renovações automáticas. As condições serão estabelecidas a partir da livre negociação de
                    valores, em comum acordo entre as partes.
                </li>
                <li>Se comprometer a orientar o TALENTO sobre a conduta profissional no mercado.</li>
            </ol>
        </div>
    );
}
