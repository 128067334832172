import React from "react";

export const Sobre = () => {
    return (
        <div className="card">
            <h3>Sobre</h3>
            Conteúdo
        </div>
    );
};
