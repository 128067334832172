import { Artist } from "../models";
import { del, get, postMultiPart } from "./request.service";
import { config } from "../utils/constants";

export async function filterArtists(type: string, filter: string): Promise<Artist[]> {
    return get(`${config.url}/search-artists/${type}/${encodeURI(filter)}`);
}

export async function listArtists(): Promise<Artist[]> {
    return get(`${config.url}/artists`);
}

export async function deleteArtist(id: string): Promise<Artist> {
    return del(`${config.url}/artists/${id}/delete`);
}

export function updateArtist(id: string, body: Partial<Artist>, picture?: Blob): Promise<Artist> {
    const formData = new FormData();
    formData.append("artistRequest", JSON.stringify(body));
    if (picture) {
        formData.append("picture", picture, "picture.jpg");
    }
    return postMultiPart(`${config.url}/artists/${id}/update`, formData);
}

export function newArtist(body: Partial<Artist>, picture?: Blob): Promise<Artist> {
    const formData = new FormData();
    formData.append("artistRequest", JSON.stringify(body));
    if (picture) {
        formData.append("picture", picture, "picture.jpg");
    }
    return postMultiPart(`${config.url}/artists/new`, formData);
}
