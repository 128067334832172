import { setDate } from "../services/util.service";

export function formatCurrency(amount: number = 0): string {
    return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
    }).format(amount);
}

export function formatDate(date: Date, datetime = false): string {
    return datetime ? date.toLocaleString("pt-BR") : date.toLocaleDateString("pt-BR");
}

export function formatMonthYear(date: Date): string {
    const formattedDate = date.toLocaleDateString("pt-BR");
    const [, month, year] = formattedDate.split("/");
    return `${month}/${year}`;
}

export function formatArtistName(artistName: string): string {
    return artistName.toLowerCase().replace(/ /g, "-");
}

export function parseDate(date: string): Date {
    const [day, month, year] = date.split("/");
    if (!day || !month || !year) {
        return new Date();
    }
    return setDate(Number(day), Number(month) - 1, Number(year));
}
