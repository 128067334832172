import { LoadingStore } from "./loading.store";
import { RouterStore } from "mobx-react-router";
import { SessionStore } from "./session.store";
import { ProfileStore } from "./profile.store";
import { ArtistStore } from "./artist.store";
import { AgencyStore } from "./agency.store";
import { SearchStore } from "./search.store";

export class RootStore {
    loadingStore: LoadingStore;
    routingStore: RouterStore;
    sessionStore: SessionStore;
    profileStore: ProfileStore;
    artistStore: ArtistStore;
    agencyStore: AgencyStore;
    searchStore: SearchStore;
    constructor() {
        this.loadingStore = new LoadingStore();
        this.routingStore = new RouterStore();
        this.sessionStore = new SessionStore(this);
        this.profileStore = new ProfileStore();
        this.artistStore = new ArtistStore();
        this.agencyStore = new AgencyStore();
        this.searchStore = new SearchStore();
    }
}
