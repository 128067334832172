import { Crop } from "react-image-crop";

export const getCroppedImg = async (
    imageSrc: string,
    crop: Crop,
    width: number,
    height: number
): Promise<{ blob: Blob; base64: string }> => {
    const createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", error => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / width;
    const scaleY = image.naturalHeight / height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width!;
    canvas.height = crop.height!;

    ctx!.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        crop.width!,
        crop.height!
    );
    return new Promise((resolve, reject) => {
        const base64 = canvas.toDataURL();
        const blob = dataURLtoBlob(base64);
        if (blob) {
            resolve({ blob, base64 });
        } else {
            reject(new Error("Could not crop image"));
        }
    });
};

function dataURLtoBlob(dataurl: string) {
    const [header, data] = dataurl.split(",");
    const [, mime] = header.match(/:(.*?);/)!;
    const bstr = atob(data);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function sortByDate<T extends any>(unsortedArray: T[], key: keyof T, sortMethod: "asc" | "desc"): T[] {
    return unsortedArray.slice().sort((p1, p2) => {
        if (!p1[key] || !p2[key]) {
            return 0;
        }
        const d1 = new Date(p1[key]);
        const d2 = new Date(p2[key]);
        if (d1.getTime() === d2.getTime()) {
            return 0;
        }
        if (sortMethod === "asc") {
            return d1.getTime() > d2.getTime() ? 1 : -1;
        }
        return d2.getTime() > d1.getTime() ? 1 : -1;
    });
}

export function sortByString<T extends any>(unsortedArray: T[], key: keyof T, sortMethod: "asc" | "desc"): T[] {
    return unsortedArray.slice().sort((p1, p2) => {
        if (!p1[key] || !p2[key]) {
            return 0;
        }
        if (p1[key] === p2[key]) {
            return 0;
        }
        if (sortMethod === "asc") {
            return p1[key].localeCompare(p2[key]);
        }
        return p2[key].localeCompare(p1[key]);
    });
}
