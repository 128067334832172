import { action, observable } from "mobx";
import { Artist } from "../models";
import { comparator, deepSearch } from "../services/util.service";

export class SearchStore {
    @observable.deep artists: Artist[] = [] as Artist[];
    @observable focusedArtist: Artist = {} as Artist;
    @observable tipo: string = "artista";
    @observable filtro: string = "";

    @action setArtists(artists: Artist[]) {
        this.artists = artists;
    }

    @action setFiltro(filtro: string) {
        this.filtro = filtro;
    }

    @action setTipo(tipo: string) {
        this.tipo = tipo;
    }

    @action updateArtist(artist: Artist) {
        const index = this.artists.findIndex(c => c._id === artist._id);
        if (index > -1) {
            this.artists[index] = artist;
        }
        this.setFocusedArtist(artist);
    }

    @action addArtist(artist: Artist) {
        this.artists.push(artist);
    }

    @action getArtistById(id: string): Artist | undefined {
        return this.artists.find(c => c._id === id);
    }

    @action removeArtistById(id: string): void {
        const index = this.artists.findIndex(c => c._id === id);
        this.artists.splice(index, 1);
    }

    @action setFocusedArtist(artist: Artist) {
        this.focusedArtist = artist;
    }

    @action filterArtists(filter: string) {
        return this.artists.filter(artist => deepSearch(artist, filter));
    }

    @action filter(name: string) {
        let artists = this.artists;
        if (name) {
            artists = artists.filter(artist => comparator(artist.artisticName, name));
        }
        return artists;
    }

    @action clearCurrentSession() {
        this.artists = [];
        this.focusedArtist = {} as Artist;
    }
}
