import React, { useCallback, useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { Avatar, Dialog, Heading, Radio, SearchInput, Table } from "evergreen-ui";
import { Agency, Artist } from "../models";
import { config } from "../utils/constants";
import { filterArtists } from "../services/artist.service";
import { toast } from "react-toastify";
import { getAlAgencies } from "../services/profile.service";

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const fixUrl = (url: string): string => {
    if (url.startsWith("http")) {
        return url;
    }
    if (url.startsWith("www")) {
        return "http://" + url;
    }
    return "http://www." + url;
};

export const Busca = inject("rootStore")(
    observer((props: Stores) => {
        const [searching, setSearching] = useState(false);
        const [showDetails, setShowDetails] = useState(false);
        const [pesquisado, setPesquisado] = useState(false);
        const [focusedArtist, setFocusedArtist] = useState<Artist | undefined>(undefined);
        const [focusedAgency, setFocusedAgency] = useState<Agency | undefined>(undefined);
        const {
            loadingStore,
            agencyStore,
            searchStore,
            routingStore: { push, location }
        } = props.rootStore!;

        const onSubmit = useCallback(
            debounce((value: string) => {
                setSearching(true);
                setPesquisado(true);
                filterArtists(searchStore.tipo === "artista" ? "art" : "esc", value)
                    .then(artists => {
                        searchStore.setArtists(artists);
                        setSearching(false);
                    })
                    .catch(() => {
                        setSearching(false);
                        toast.error("Erro ao filtrar artistas");
                    });
            }, 500),
            [searchStore.tipo]
        );

        useEffect(() => {
            if (agencyStore.agencys.length === 0) {
                loadingStore.triggerLoading();
                getAlAgencies()
                    .then(agencies => {
                        agencyStore.setAgencys(agencies);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error("Erro ao carregar escritórios");
                    });
            }
        }, [agencyStore, loadingStore]);

        useEffect(() => {
            if (location.search) {
                const novoTipo = decodeURIComponent(new URLSearchParams(location.search).get("tipo") ?? "");
                if (novoTipo && (novoTipo === "artista" || novoTipo === "escritório")) {
                    searchStore.setTipo(novoTipo);
                }
                const nome = decodeURIComponent(new URLSearchParams(location.search).get("nome") ?? "");
                if (nome && nome.length > 2) {
                    searchStore.setFiltro(nome);
                    onSubmit(nome);
                    push("/busca");
                }
            }
        }, [location.search, onSubmit]);

        const onChange = useCallback(
            (tipo: string) => {
                searchStore.setTipo(tipo);
                const filtro = searchStore.filtro;
                if (filtro && filtro.length > 2) {
                    onSubmit(filtro);
                } else {
                    searchStore.setArtists([]);
                }
            },
            [searchStore.filtro, onSubmit]
        );

        return (
            <>
                <Dialog
                    isShown={showDetails}
                    title="Detalhes do artista"
                    onCloseComplete={() => setShowDetails(false)}
                    hasFooter={false}
                    header={
                        <div className="artist-dialog">
                            <Avatar
                                src={focusedArtist?.picture ? config.host + focusedArtist.picture : undefined}
                                name={focusedArtist?.artisticName}
                                size={70}
                            />
                            <div className="artist-dialog__title">
                                <span>{focusedArtist?.artisticName}</span>
                                <span className="artist-dialog__title-website">
                                    {focusedArtist?.website && (
                                        <a href={fixUrl(focusedArtist.website)} target="_blank" rel="noreferrer">
                                            {focusedArtist?.website}
                                        </a>
                                    )}
                                </span>
                            </div>
                        </div>
                    }
                >
                    <div className="artist-dialog__agency">
                        <Avatar
                            isSolid
                            color="blue"
                            src={focusedAgency?.picture ? config.host + focusedAgency.picture : undefined}
                            name={focusedAgency?.name}
                            size={100}
                        />
                        <ul>
                            <li>
                                <Heading is="h1" color="dark">
                                    <strong className="escritorio">{focusedAgency?.name}</strong>
                                </Heading>
                            </li>
                            <li>
                                Website:{" "}
                                {focusedAgency?.website && (
                                    <a href={fixUrl(focusedAgency.website)} target="_blank" rel="noreferrer">
                                        {focusedAgency.website}
                                    </a>
                                )}
                            </li>
                            <li>
                                Informações para contato: <br />
                                {focusedAgency?.contactInfo}
                            </li>
                        </ul>
                    </div>
                </Dialog>
                <div className="bem-vindo">
                    <span>
                        Bem vindo ao <strong>Agentes Brasil</strong>
                    </span>
                    <span>Encontre informações de contato dos artistas brasileiros</span>
                </div>
                <div className="card">
                    <div className="radio-group-inline">
                        <Radio
                            name="tipo"
                            checked={searchStore.tipo === "artista"}
                            label="Busca por Artista"
                            value="artista"
                            onChange={() => onChange("artista")}
                        />
                        <Radio
                            name="tipo"
                            checked={searchStore.tipo === "escritório"}
                            label="Busca por Escritório"
                            value="escritório"
                            marginLeft={10}
                            onChange={() => onChange("escritório")}
                        />
                    </div>
                    <SearchInput
                        placeholder={`Nome do ${searchStore.tipo}`}
                        marginTop={10}
                        marginBottom={10}
                        width="100%"
                        value={searchStore.filtro}
                        onChange={(e: any) => {
                            const value = e.target.value;
                            searchStore.setFiltro(value);
                            if (value && value.length > 2) {
                                onSubmit(value);
                            } else {
                                searchStore.setArtists([]);
                            }
                        }}
                        isInvalid={!!searchStore.filtro && searchStore.filtro.length < 3}
                    />
                    <Table marginTop={10}>
                        {pesquisado && (
                            <Table.Head>
                                <Table.TextHeaderCell>Foto</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Nome do artista</Table.TextHeaderCell>
                                <Table.TextHeaderCell>Escritório</Table.TextHeaderCell>
                            </Table.Head>
                        )}
                        <Table.Body>
                            {searching && (
                                <Table.Row>
                                    <Table.TextCell>Pesquisando...</Table.TextCell>
                                </Table.Row>
                            )}
                            {!searching && pesquisado && searchStore.artists.length === 0 && (
                                <Table.Row>
                                    <Table.TextCell>Nenhum artista encontrado</Table.TextCell>
                                </Table.Row>
                            )}
                            {!searching &&
                                searchStore.artists.map(artist => (
                                    <Table.Row
                                        key={artist._id}
                                        isSelectable
                                        onSelect={() => {
                                            setFocusedAgency(agencyStore.getAgencyById(artist.office));
                                            setFocusedArtist(artist);
                                            setShowDetails(true);
                                        }}
                                        height={100}
                                    >
                                        <Table.TextCell>
                                            <Avatar
                                                src={artist.picture ? config.host + artist.picture : undefined}
                                                name={artist.artisticName}
                                                size={70}
                                            />
                                        </Table.TextCell>
                                        <Table.TextCell>{artist.artisticName}</Table.TextCell>
                                        <Table.TextCell className="escritorio">
                                            {agencyStore.getAgencyById(artist.office)?.name}
                                        </Table.TextCell>
                                    </Table.Row>
                                ))}
                        </Table.Body>
                    </Table>
                </div>
            </>
        );
    })
);
