import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { useEffect, useState } from "react";
import { getAlAgencies } from "../services/profile.service";
import { toast } from "react-toastify";
import { Avatar, Dialog, Heading } from "evergreen-ui";
import { config } from "../utils/constants";
import { fixUrl } from "./Busca";
import { Agency } from "../models";

export const Escritorios = inject("rootStore")(
    observer((props: Stores) => {
        const {
            loadingStore,
            agencyStore,
            routingStore: { push }
        } = props.rootStore!;
        const [showDetails, setShowDetails] = useState(false);
        const [focusedAgency, setFocusedAgency] = useState<Agency | undefined>(undefined);

        useEffect(() => {
            if (agencyStore.agencys.length === 0) {
                loadingStore.triggerLoading();
                getAlAgencies()
                    .then(agencies => {
                        agencyStore.setAgencys(agencies);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error("Erro ao carregar escritórios");
                    });
            }
        }, [agencyStore, loadingStore]);

        return (
            <div className="card">
                <Dialog
                    isShown={showDetails}
                    title="Detalhes do escritório"
                    onCloseComplete={() => setShowDetails(false)}
                    hasFooter={false}
                    hasHeader={false}
                >
                    <div className="artist-dialog__agency">
                        <Avatar
                            isSolid
                            color="blue"
                            src={focusedAgency?.picture ? config.host + focusedAgency.picture : undefined}
                            name={focusedAgency?.name}
                            size={100}
                        />
                        <ul>
                            <li>
                                <Heading is="h1" color="dark">
                                    <strong className="escritorio">{focusedAgency?.name}</strong>
                                </Heading>
                            </li>
                            <li>
                                Website:{" "}
                                {focusedAgency?.website && (
                                    <a href={fixUrl(focusedAgency.website)} target="_blank" rel="noreferrer">
                                        {focusedAgency.website}
                                    </a>
                                )}
                            </li>
                            <li>
                                <br />
                            </li>
                            <li>
                                <div
                                    className="clickable"
                                    style={{ fontSize: "0.9rem" }}
                                    onClick={() => {
                                        push(
                                            `/?tipo=${encodeURIComponent("escritório")}&nome=${encodeURIComponent(
                                                focusedAgency?.name.toLowerCase() ?? ""
                                            )}`
                                        );
                                    }}
                                >
                                    Visualizar os artistas representados pelo escritório
                                </div>
                            </li>
                        </ul>
                    </div>
                </Dialog>
                <h3>Escritórios participantes da plataforma</h3>
                <div className="lista-escritorios">
                    {agencyStore.agencys.map(agency => (
                        <div
                            className="clickable"
                            onClick={() => {
                                setFocusedAgency(agency);
                                setShowDetails(true);
                            }}
                        >
                            {agency.name.toLowerCase()}
                        </div>
                    ))}
                </div>
            </div>
        );
    })
);
