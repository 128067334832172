import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React from "react";
import { Formik, FormikHelpers } from "formik";
import { Button, Checkbox, CrossIcon, FloppyDiskIcon } from "evergreen-ui";
import { agreeToTerms } from "../services/profile.service";
import { toast } from "react-toastify";

export const FirstLogin = inject("rootStore")(
    observer((props: Stores) => {
        const {
            loadingStore,
            sessionStore,
            profileStore,
            routingStore: { push }
        } = props.rootStore!;
        const onSubmit = async (values: any, { setErrors, resetForm, validateForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            agreeToTerms({
                password: values.newPassword,
                agreed: true
            })
                .then(profile => {
                    profileStore.updateProfile(profile);
                    sessionStore.updateProfile(profile);
                    toast.success("Confirmação de termos efetuada com sucesso");
                    loadingStore.stopLoading();
                    push("/");
                })
                .catch(() => {
                    toast.error("Erro ao concordar com termos");
                    loadingStore.stopLoading();
                });
        };
        return (
            <Formik
                initialValues={{
                    agreed: []
                }}
                validate={values => {
                    let errors: any = {};
                    if (!values.agreed || values.agreed[0] !== "yes") {
                        errors.agreed = "Campo obrigatório";
                    }
                    return errors;
                }}
                onSubmit={onSubmit}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="flex-table first-login-card">
                            <div className="flex-row">
                                <div className="flex-column">
                                    <div className="terms">
                                        <p>
                                            <strong>Termos de uso</strong>
                                        </p>
                                        <p>
                                            Cláusula primeira: A An&ecirc;mona (CONTRATADA) coletará, utilizará e armazenará
                                            os dados informados pelos escritórios de agenciamento (CONTRATANTE) em
                                            conformidade com as leis aplicáveis, incluindo a Lei Federal nº 13.709/2018
                                            (“LGPD”) e com as políticas e normas internas. As operações de tratamento de
                                            dados pessoais realizadas pela CONTRATADA estão fundamentadas na execução do
                                            contrato de prestação de serviços aos escritórios e no cumprimento de
                                            obrigações legais, a exemplo do legítimo interesse da CONTRATADA no
                                            exercício regular de suas funções, responsabilidades e para a condução das
                                            suas atividades previstas.
                                        </p>
                                        <p>
                                            Cláusula segunda: A responsabilidade pelas informações cadastradas na
                                            plataforma tais como nomes de artistas e suas respectivas páginas pessoais
                                            é, em totalidade, do CONTRATANTE que as cadastrar. Entende-se a partir desse
                                            momento que a CONTRATANTE possui autorização legal, ou equivalente, para tal
                                            e isenta, portanto, a CONTRATADA de qualquer responsabilidade da no que diz
                                            respeito a tais dados.
                                        </p>
                                        <p>
                                            Cláusula terceira: Rescindido o contrato de prestação de serviços, a
                                            CONTRATADA excluíra todos os dados informados pela CONTRATANTE enquanto
                                            usuária da plataforma, de forma a assegurar os direitos da CONTRATANTE e do
                                            próprio artista como titular dos dados.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-row">
                                <div className="flex-column">
                                    <Checkbox
                                        label="Confirmo que li e concordo com os termos acima"
                                        name="agreed"
                                        value="yes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        checked={values.agreed[0] === "yes"}
                                    />
                                    {errors && errors.agreed && (
                                        <span className="invalid-feedback" style={{ display: "block" }}>
                                            {errors.agreed}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    className="primary"
                                    iconAfter={<FloppyDiskIcon />}
                                >
                                    Prosseguir
                                </Button>
                                <Button
                                    appearance="primary"
                                    intent="danger"
                                    iconAfter={<CrossIcon />}
                                    onClick={() => {
                                        sessionStore.clearCurrentSession();
                                        push("/login");
                                    }}
                                    style={{ marginLeft: "10px" }}
                                >
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        );
    })
);
