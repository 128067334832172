import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "./i18n";
import { defaultTheme, ThemeProvider } from "evergreen-ui";

const newTheme = {
    ...defaultTheme,
    spinnerColor: "red"
};

newTheme.fills.solid.blue.backgroundColor = "#0d0d0d";
newTheme.colors.text.default = "#0d0d0d";
newTheme.colors.text.dark = "#0d0d0d";
newTheme.colors.intent.none = "#0d0d0d";
newTheme.palette.blue.base = "#0d0d0d";
newTheme.scales.neutral.N10 = "#0d0d0d";
newTheme.scales.blue.B3A = "#0d0d0d";

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider value={newTheme}>
            <App />
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
