import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers } from "formik";
import { changePassword } from "../services/profile.service";
import { toast } from "react-toastify";
import { Avatar, Button, FormField, TextInput, Dialog, Popover, Position, Menu, LogInIcon } from "evergreen-ui";
import { Login } from "./Login";
import { config } from "../utils/constants";
import { faTwitter, faYoutube, faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkToken } from "../services/login.service";

export const Header = inject("rootStore")(
    observer((props: Stores): ReactElement | null => {
        const [login, setLogin] = useState(false);
        const [showChangePassword, setChangePassword] = useState(false);
        const { t } = useTranslation();
        const {
            routingStore: { push },
            sessionStore,
            profileStore,
            loadingStore,
            artistStore
        } = props.rootStore!;

        useEffect(() => {
            if (sessionStore.session.profile?.shouldChangePassword) {
                setChangePassword(true);
            }
        }, [sessionStore.session.profile]);

        useEffect(() => {
            if (sessionStore.logged) {
                checkToken()
                    .then(response => {
                        if (response && response.status === "expiring" && response.token) {
                            sessionStore.setCurrentSession({
                                ...sessionStore.session,
                                token: response.token
                            });
                        }
                    })
                    .catch(response => {
                        if (response.status === 401) {
                            profileStore.clearCurrentSession();
                            sessionStore.clearCurrentSession();
                            push("/login");
                        }
                    });
            }
        });

        const onSubmit = async (values: any, { resetForm }: FormikHelpers<any>) => {
            loadingStore.triggerLoading();
            setChangePassword(false);
            changePassword(values.newPassword)
                .then(result => {
                    loadingStore.stopLoading();
                    if (result._id) {
                        toast.success(t("success.updated"));
                        profileStore.updateProfile(result);
                        sessionStore.updateProfile(result);
                        resetForm();
                    } else {
                        setChangePassword(true);
                        toast.error(t("error.updating"));
                    }
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    setChangePassword(true);
                    toast.error(t("error.inviting"));
                });
        };

        return (
            <>
                <Dialog
                    hasFooter={false}
                    isShown={login}
                    title="Acesso restrito"
                    onCloseComplete={() => setLogin(false)}
                >
                    <Login onSuccess={() => setLogin(false)} />
                </Dialog>
                <Dialog
                    hasFooter={false}
                    isShown={showChangePassword}
                    title={t("menu.change_password")}
                    onCloseComplete={() => setChangePassword(false)}
                    preventBodyScrolling
                >
                    <Formik
                        initialValues={{
                            newPassword: "",
                            newPasswordCheck: ""
                        }}
                        validate={values => {
                            let errors: any = {};
                            if (!values.newPassword) {
                                errors.newPassword = t("form.errors.required");
                            }
                            if (!values.newPasswordCheck) {
                                errors.newPasswordCheck = t("form.errors.required");
                            } else if (values.newPassword !== values.newPasswordCheck) {
                                errors.newPasswordCheck = t("form.errors.invalid");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-table">
                                    <div>
                                        <FormField label={t("profile.new_password")} labelFor="newPassword" />
                                        <TextInput
                                            type="password"
                                            name="newPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.newPassword}
                                            isInvalid={!!errors.newPassword}
                                        />
                                        {errors && errors.newPassword && (
                                            <span className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.newPassword}
                                            </span>
                                        )}
                                    </div>
                                    <div>
                                        <FormField
                                            label={t("profile.new_password_confirmation")}
                                            labelFor="newPasswordCheck"
                                        />
                                        <TextInput
                                            type="password"
                                            name="newPasswordCheck"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.newPasswordCheck}
                                            isInvalid={!!errors.newPasswordCheck}
                                        />
                                        {errors && errors.newPasswordCheck && (
                                            <span className="invalid-feedback" style={{ display: "block" }}>
                                                {errors.newPasswordCheck}
                                            </span>
                                        )}
                                    </div>
                                    <div className="dialog-footer">
                                        <Button type="submit" appearance="primary" className="primary">
                                            {t("proceed")}
                                        </Button>
                                        <Button
                                            appearance="primary"
                                            intent="danger"
                                            onClick={() => {
                                                resetForm();
                                            }}
                                            style={{ marginLeft: "10px" }}
                                        >
                                            {t("cancel")}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <div className="header">
                    <div className="logo">
                        <img src="/assets/logo.png" height={70} alt="logo" />
                    </div>
                    <div className="profile">
                        {sessionStore.logged ? (
                            <Popover
                                shouldCloseOnExternalClick
                                position={Position.BOTTOM_RIGHT}
                                content={
                                    <Menu>
                                        <Menu.Group>
                                            <Menu.Item
                                                onSelect={() => setChangePassword(true)}
                                                className="css-b5v4p5 menu-item"
                                            >
                                                Alterar senha
                                            </Menu.Item>
                                        </Menu.Group>
                                        <Menu.Divider />
                                        <Menu.Group>
                                            <Menu.Item
                                                onSelect={() => {
                                                    sessionStore.clearCurrentSession();
                                                    artistStore.clearCurrentSession();
                                                    profileStore.clearCurrentSession();
                                                    toast.success("Sessão encerrada com sucesso");
                                                    push("/");
                                                }}
                                                intent="danger"
                                            >
                                                Sair
                                            </Menu.Item>
                                        </Menu.Group>
                                    </Menu>
                                }
                            >
                                <Avatar
                                    isSolid
                                    color="red"
                                    src={
                                        sessionStore.session.profile.picture
                                            ? config.host + sessionStore.session.profile.picture
                                            : undefined
                                    }
                                    name={sessionStore.session.profile.name}
                                    size={40}
                                />
                            </Popover>
                        ) : (
                            <Button
                                appearance="primary"
                                className="primary"
                                iconAfter={<LogInIcon />}
                                onClick={() => setLogin(true)}
                            >
                                Acesso restrito
                            </Button>
                        )}
                    </div>
                </div>
                <div className="menu">
                    <div>
                        {(!sessionStore.logged || sessionStore.isOffice) && (
                            <>
                                <a onClick={() => push("/")}>Busca</a>
                                <a onClick={() => push("/escritorios")}>Escritórios</a>
                                <a onClick={() => push("/codigo-etica")}>Código de Ética</a>
                            </>
                        )}
                        {sessionStore.logged && sessionStore.isOffice && (
                            <>
                                <a onClick={() => push("/cadastro")}>Cadastro</a>
                                <a onClick={() => push("/elenco")}>Elenco</a>
                            </>
                        )}
                        {sessionStore.logged && sessionStore.isDirector && (
                            <>
                                <a onClick={() => push("/admin")}>Administração</a>
                            </>
                        )}
                        {(!sessionStore.logged || sessionStore.isOffice) && (
                            <>
                                <a onClick={() => push("/sobre")}>Sobre</a>
                            </>
                        )}
                    </div>
                    <div>
                        <a
                            href="https://youtube.com/channel/UCx7RfeiLXDNRwYJe6TYncug"
                            target="_blank"
                            rel="noreferrer"
                            title="YouTube"
                        >
                            <FontAwesomeIcon icon={faYoutube} color="#0d0d0d" />
                        </a>
                        <a href="https://twitter.com/agentesdobrasil" target="_blank" rel="noreferrer" title="Twitter">
                            <FontAwesomeIcon icon={faTwitter} color="#0d0d0d" />
                        </a>
                        <a
                            href="https://fb.com/Agentes-do-Brasil-102950685010621"
                            target="_blank"
                            rel="noreferrer"
                            title="Facebook"
                        >
                            <FontAwesomeIcon icon={faFacebook} color="#0d0d0d" />
                        </a>
                        <a
                            href="https://instagram.com/agentesdobrasil"
                            target="_blank"
                            rel="noreferrer"
                            title="Instagram"
                        >
                            <FontAwesomeIcon icon={faInstagram} color="#0d0d0d" />
                        </a>
                    </div>
                </div>
            </>
        );
    })
);
