import { action, observable } from "mobx";
import { Profile } from "../models";
import { comparator, deepSearch } from "../services/util.service";

export class ProfileStore {
    @observable.deep profiles: Profile[] = [] as Profile[];
    @observable focusedProfile: Profile = {} as Profile;

    @action setProfiles(profiles: Profile[]) {
        this.profiles = profiles;
    }

    @action updateProfile(profile: Profile) {
        const index = this.profiles.findIndex(c => c._id === profile._id);
        if (index > -1) {
            this.profiles[index] = profile;
        }
        this.setFocusedProfile(profile);
    }

    @action addProfile(profile: Profile) {
        this.profiles.push(profile);
    }

    @action getProfileById(id: string): Profile | undefined {
        return this.profiles.find(c => c._id === id);
    }

    @action setFocusedProfile(profile: Profile) {
        this.focusedProfile = profile;
    }

    @action filterProfiles(filter: string) {
        return this.profiles.filter(profile => deepSearch(profile, filter));
    }

    @action filter(name: string, status?: number) {
        let profiles = this.profiles;
        if (name) {
            profiles = profiles.filter(profile => comparator(profile.name, name));
        }

        if (status && status > -1) {
            profiles = profiles.filter(profile => profile.status === Number(status));
        }
        return profiles;
    }

    @action clearCurrentSession() {
        this.profiles = [];
        this.focusedProfile = {} as Profile;
    }
}
