import { action, computed, observable } from "mobx";
import { Profile, Session } from "../models";
import { getCurrentSession, setCurrentSession } from "../utils/login.utils";
import { GrantEnum } from "../models/generic";
import { RootStore } from "./root.store";

export class SessionStore {
    private rootStore: RootStore;
    @observable.deep session: Session = {} as Session;

    constructor(rootStore: RootStore) {
        const session = getCurrentSession();
        if (session != null) {
            this.session = session;
        }
        this.rootStore = rootStore;
    }

    @action clearCurrentSession() {
        this.session = {} as Session;
        setCurrentSession();
    }

    @action setCurrentSession(session: Session) {
        this.session = session;
        setCurrentSession(session);
    }

    @action updateProfile(profile: Profile) {
        this.session.profile = profile;
        setCurrentSession(this.session);
    }

    @computed get currentSession() {
        if (!this.session || !this.session.token) {
            return getCurrentSession();
        }
        return this.session;
    }

    @computed get logged(): boolean {
        const sessionStore = this.session ? !!this.session.token : false;
        return sessionStore || !!getCurrentSession();
    }

    @computed get isDirector(): boolean {
        return (
            this.session.profile &&
            this.session.profile.role &&
            this.session.profile.role?.grants === GrantEnum.DIRECTOR
        );
    }

    @computed get isOffice(): boolean {
        return (
            this.session.profile && this.session.profile.role && this.session.profile.role?.grants === GrantEnum.OFFICE
        );
    }
}
