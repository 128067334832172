import { observable, action } from "mobx";

export class LoadingStore {
    @observable loading = false;

    @action stopLoading() {
        this.loading = false;
    }

    @action triggerLoading() {
        this.loading = !this.loading;
    }
}
