import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import { Profile } from "../models";
import { validateCPF } from "../services/util.service";
import { Formik } from "formik";
import { Avatar, Button, FilePicker, Heading, Label, SelectField, TextareaField, TextInputField } from "evergreen-ui";
import InputMask from "react-input-mask";
import { config, states } from "../utils/constants";
import { formatDate } from "../utils/formats";
import { updateProfile } from "../services/profile.service";
import { toast } from "react-toastify";

export const Cadastro = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const { loadingStore, sessionStore } = props.rootStore!;
        const [file, setFile] = useState<File | null>(null);
        const [profile, setProfile] = useState<Profile>(sessionStore.session.profile);

        const onSubmit = async (values: Partial<Profile>) => {
            loadingStore.triggerLoading();
            updateProfile(values, file)
                .then(profile => {
                    sessionStore.updateProfile(profile);
                    setProfile(profile);
                    loadingStore.stopLoading();
                    toast.success("Dados atualizado com sucesso");
                })
                .catch(() => {
                    loadingStore.stopLoading();
                    toast.error("Erro ao atualizar dados");
                });
        };
        return (
            <div className="card" style={{ width: "50vw", alignSelf: "center" }}>
                <Heading is="h1">
                    <strong>Dados do escritório</strong>
                </Heading>
                <Formik
                    initialValues={{
                        cnpj: profile.cnpj,
                        name: profile.name,
                        city: profile.city,
                        state: profile.state,
                        email: profile.email,
                        website: profile.website,
                        responsible: profile.responsible,
                        responsibleDocument: profile.responsibleDocument,
                        contactInfo: profile.contactInfo
                    }}
                    enableReinitialize
                    validate={values => {
                        // same as above, but feel free to move this into a class method now.
                        let errors: Partial<Profile> = {};
                        if (!values.name) {
                            errors.name = t("form.errors.required");
                        }
                        if (!values.city) {
                            errors.city = t("form.errors.required");
                        }
                        if (!values.state) {
                            errors.state = t("form.errors.required");
                        }
                        if (!values.email) {
                            errors.email = t("form.errors.required");
                        }
                        if (!values.website) {
                            errors.website = t("form.errors.required");
                        }
                        if (!values.responsible) {
                            errors.responsible = t("form.errors.required");
                        }
                        if (!values.responsibleDocument) {
                            errors.responsibleDocument = t("form.errors.required");
                        } else if (!validateCPF(values.responsibleDocument)) {
                            errors.responsibleDocument = t("form.errors.invalid");
                        }
                        if (!values.contactInfo) {
                            errors.contactInfo = t("form.errors.required");
                        }
                        return errors;
                    }}
                    onSubmit={onSubmit}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, resetForm }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex-table">
                                <div className="flex-row">
                                    <div className="flex-column">
                                        <div className="flex-table">
                                            <div className="flex-row" style={{ flexGrow: 0, justifyContent: "center" }}>
                                                <Avatar
                                                    src={profile.picture ? config.host + profile.picture : undefined}
                                                    name={profile.name}
                                                    size={250}
                                                />
                                            </div>
                                            <div className="flex-row" style={{ flexGrow: 0 }}>
                                                <FilePicker
                                                    multiple={false}
                                                    width="100%"
                                                    marginTop={16}
                                                    marginBottom={24}
                                                    accept="image/*"
                                                    onChange={e => {
                                                        if (e.length > 0) {
                                                            setFile(e[0]);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className="flex-row"
                                                style={{ flexGrow: 0, marginBottom: 24, flexDirection: "column" }}
                                            >
                                                <Label>Data do cadastro:</Label>
                                                <strong>
                                                    {profile.createdAt ? formatDate(new Date(profile.createdAt)) : "--"}
                                                </strong>
                                            </div>
                                            <div
                                                className="flex-row"
                                                style={{ flexGrow: 0, marginBottom: 24, flexDirection: "column" }}
                                            >
                                                <Label>Última renovação:</Label>
                                                <strong>
                                                    {profile.lastRenew ? formatDate(new Date(profile.lastRenew)) : "--"}
                                                </strong>
                                            </div>
                                            <div
                                                className="flex-row"
                                                style={{ flexGrow: 0, marginBottom: 24, flexDirection: "column" }}
                                            >
                                                <Label>Vencimento da assinatura:</Label>
                                                <strong>
                                                    {profile.subscriptionExpiry
                                                        ? formatDate(new Date(profile.subscriptionExpiry))
                                                        : "--"}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-column">
                                        <div className="flex-table">
                                            <div className="flex-row">
                                                <InputMask
                                                    mask="99.999.999/9999-99"
                                                    value={values && values.cnpj}
                                                    disabled={true}
                                                >
                                                    {(inputProps: any) => (
                                                        <TextInputField
                                                            {...inputProps}
                                                            label="CNPJ:"
                                                            name="cnpj"
                                                            disabled
                                                            width="100%"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>
                                            <div className="flex-row">
                                                <TextInputField
                                                    type="text"
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.name}
                                                    label="Nome:"
                                                    isInvalid={!!errors.name}
                                                    validationMessage={errors.name}
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="flex-row">
                                                <TextInputField
                                                    type="text"
                                                    name="city"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.city}
                                                    label="Cidade:"
                                                    isInvalid={!!errors.city}
                                                    validationMessage={errors.city}
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="flex-row">
                                                <SelectField
                                                    name="state"
                                                    onChange={handleChange}
                                                    value={values && values.state}
                                                    label="Estado:"
                                                    isInvalid={!!errors.state}
                                                    validationMessage={errors.state}
                                                    width="100%"
                                                >
                                                    <option value={undefined}>Selecione</option>
                                                    {states.map(state => (
                                                        <option key={state.code} value={state.code}>
                                                            {state.name}
                                                        </option>
                                                    ))}
                                                </SelectField>
                                            </div>
                                            <div className="flex-row">
                                                <TextInputField
                                                    type="text"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.email}
                                                    label="E-mail:"
                                                    isInvalid={!!errors.email}
                                                    validationMessage={errors.email}
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="flex-row">
                                                <TextInputField
                                                    type="text"
                                                    name="website"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.website}
                                                    label="Website:"
                                                    isInvalid={!!errors.website}
                                                    validationMessage={errors.website}
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="flex-row">
                                                <TextInputField
                                                    type="text"
                                                    name="responsible"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.responsible}
                                                    label="Responsável:"
                                                    isInvalid={!!errors.responsible}
                                                    validationMessage={errors.responsible}
                                                    width="100%"
                                                />
                                            </div>
                                            <div className="flex-row">
                                                <InputMask
                                                    mask="999.999.999-99"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values && values.responsibleDocument}
                                                >
                                                    {(inputProps: any) => (
                                                        <TextInputField
                                                            {...inputProps}
                                                            type="text"
                                                            name="responsibleDocument"
                                                            label="CPF do responsável:"
                                                            isInvalid={!!errors.responsibleDocument}
                                                            validationMessage={errors.responsibleDocument}
                                                            width="100%"
                                                        />
                                                    )}
                                                </InputMask>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-row" style={{ flexDirection: "column" }}>
                                    <TextareaField
                                        name="contactInfo"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values && values.contactInfo}
                                        label="Informações para contato:"
                                        isInvalid={!!errors.contactInfo}
                                        validationMessage={errors.contactInfo}
                                        width="100%"
                                        marginBottom={0}
                                    />
                                    <span style={{ fontSize: 12, marginBottom: 24 }}>
                                        Incluir nomes, telefones, e-mails e demais informações de contato. Esse será o
                                        texto demonstrado nas consultas públicas de seus artistas.
                                    </span>
                                </div>
                                <div className="flex-row">
                                    <Button
                                        type="submit"
                                        appearance="primary"
                                        className="primary"
                                        justifyContent="center"
                                    >
                                        {t("save")}
                                    </Button>
                                    <Button
                                        appearance="primary"
                                        intent="danger"
                                        onClick={(e: any) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            resetForm();
                                            setFile(null);
                                        }}
                                        style={{ marginLeft: "10px" }}
                                    >
                                        {t("cancel")}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        );
    })
);
