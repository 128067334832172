import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import React, { useEffect, useState } from "react";
import { Avatar, Button, Dialog, FormField, SearchInput, Switch, Table, TextInput, TextInputField } from "evergreen-ui";
import { config } from "../utils/constants";
import { getProfiles, newProfile, updateProfileAdmin } from "../services/profile.service";
import { toast } from "react-toastify";
import { Profile, StatusEnum } from "../models";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { formatDate, parseDate } from "../utils/formats";
import { validateCNPJ } from "../services/util.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { sortByDate, sortByString } from "../utils/utils";

export const Administracao = inject("rootStore")(
    observer((props: Stores) => {
        const { loadingStore, profileStore } = props.rootStore!;
        const { t } = useTranslation();
        const [filter, setFilter] = useState("");
        const [escritorios, setEscritorios] = useState<Profile[]>([]);
        const [focusedOffice, setFocusedOffice] = useState<Profile | undefined>(undefined);
        const [showDetails, setShowDetails] = useState(false);
        const [sortVencimento, setSortVencimento] = useState<"asc" | "desc">("asc");
        const [sortNome, setSortNome] = useState<"asc" | "desc">("asc");
        const [sortPagamento, setSortPagamento] = useState<"asc" | "desc">("asc");
        const [sort, setSort] = useState("nome");

        useEffect(() => {
            if (profileStore.profiles.length === 0) {
                loadingStore.triggerLoading();
                getProfiles()
                    .then(profiles => {
                        profileStore.setProfiles(profiles);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error("Erro ao carregar escritórios");
                    });
            }
        }, [profileStore, loadingStore]);

        useEffect(() => {
            if (filter) {
                setEscritorios(profileStore.filter(filter, undefined));
            } else {
                setEscritorios(profileStore.profiles);
            }
        }, [profileStore, profileStore.profiles, filter]);

        const onSubmit = async (values: any) => {
            setShowDetails(false);
            loadingStore.triggerLoading();
            if (focusedOffice) {
                updateProfileAdmin(focusedOffice._id!, {
                    lastRenew: parseDate(values.lastRenewString),
                    subscriptionExpiry: parseDate(values.subscriptionExpiryString),
                    ...(values.newPassword && values.newPasswordCheck ? { password: values.newPassword } : {}),
                    active: values.ativo
                })
                    .then(profile => {
                        loadingStore.stopLoading();
                        toast.success("Dados do escritório atualizados com sucesso");
                        setFocusedOffice(undefined);
                        profileStore.updateProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setShowDetails(true);
                        toast.error("Ocorreu um erro ao atualizar os dados do escritório");
                    });
            } else {
                newProfile({
                    name: values.name,
                    cnpj: values.cnpj,
                    lastRenew: parseDate(values.lastRenewString),
                    subscriptionExpiry: parseDate(values.subscriptionExpiryString),
                    password: values.newPassword,
                    active: values.ativo
                })
                    .then(profile => {
                        loadingStore.stopLoading();
                        toast.success("Escritório cadastrado com sucesso");
                        profileStore.addProfile(profile);
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        setShowDetails(true);
                        toast.error("Ocorreu um erro ao cadastrar escritório");
                    });
            }
        };

        return (
            <>
                <Dialog
                    isShown={showDetails}
                    title={focusedOffice ? "Detalhes do escritório" : "Novo escritório"}
                    onCloseComplete={() => {
                        setShowDetails(false);
                        setFocusedOffice(undefined);
                    }}
                    hasFooter={false}
                >
                    <Formik
                        initialValues={{
                            name: focusedOffice?.name,
                            cnpj: focusedOffice?.cnpj,
                            lastRenewString: focusedOffice?.lastRenew
                                ? formatDate(new Date(focusedOffice.lastRenew))
                                : "",
                            subscriptionExpiryString: focusedOffice?.subscriptionExpiry
                                ? formatDate(new Date(focusedOffice.subscriptionExpiry))
                                : "",
                            newPassword: "",
                            newPasswordCheck: "",
                            ativo: focusedOffice ? focusedOffice.status === StatusEnum.ACTIVE : true
                        }}
                        enableReinitialize
                        validate={values => {
                            // same as above, but feel free to move this into a class method now.
                            let errors: any = {};
                            if (!values.name) {
                                errors.name = t("form.errors.required");
                            }
                            if (!values.cnpj) {
                                errors.cnpj = t("form.errors.required");
                            } else if (!validateCNPJ(values.cnpj)) {
                                errors.cnpj = t("form.errors.invalid");
                            }

                            if (!values.lastRenewString) {
                                errors.lastRenewString = t("form.errors.required");
                            }
                            if (!values.subscriptionExpiryString) {
                                errors.subscriptionExpiryString = t("form.errors.required");
                            }
                            if (!focusedOffice) {
                                if (!values.newPassword) {
                                    errors.newPassword = t("form.errors.required");
                                }
                            }
                            if (values.newPassword && !values.newPasswordCheck) {
                                errors.newPasswordCheck = t("form.errors.required");
                            } else if (values.newPassword && values.newPassword !== values.newPasswordCheck) {
                                errors.newPasswordCheck = t("form.errors.invalid");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-table">
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <div className="flex-table">
                                                {focusedOffice && (
                                                    <div
                                                        className="flex-row"
                                                        style={{ flexGrow: 0, justifyContent: "center" }}
                                                    >
                                                        <Avatar
                                                            src={
                                                                focusedOffice?.picture
                                                                    ? config.host + focusedOffice.picture
                                                                    : undefined
                                                            }
                                                            name={focusedOffice?.name}
                                                            size={100}
                                                        />
                                                    </div>
                                                )}
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <TextInputField
                                                            type="text"
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values && values.name}
                                                            label="Nome:"
                                                            isInvalid={!!errors.name}
                                                            validationMessage={errors.name}
                                                            width="100%"
                                                            disabled={!!focusedOffice}
                                                            marginBottom={0}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <InputMask
                                                            mask="99.999.999/9999-99"
                                                            value={values && values.cnpj}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={!!focusedOffice}
                                                        >
                                                            {(inputProps: any) => (
                                                                <TextInputField
                                                                    {...inputProps}
                                                                    label="CNPJ:"
                                                                    name="cnpj"
                                                                    width="100%"
                                                                    isInvalid={!!errors.cnpj}
                                                                    validationMessage={errors.cnpj}
                                                                    disabled={!!focusedOffice}
                                                                    marginBottom={0}
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                </div>
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <InputMask
                                                            mask="99/99/9999"
                                                            value={values && values.lastRenewString}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            {(inputProps: any) => (
                                                                <TextInputField
                                                                    {...inputProps}
                                                                    label="Data do pagamento:"
                                                                    name="lastRenewString"
                                                                    width="100%"
                                                                    isInvalid={!!errors.lastRenewString}
                                                                    validationMessage={errors.lastRenewString}
                                                                    marginBottom={0}
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                    <div className="flex-column">
                                                        <InputMask
                                                            mask="99/99/9999"
                                                            value={values && values.subscriptionExpiryString}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        >
                                                            {(inputProps: any) => (
                                                                <TextInputField
                                                                    {...inputProps}
                                                                    label="Data do vencimento:"
                                                                    name="subscriptionExpiryString"
                                                                    width="100%"
                                                                    isInvalid={!!errors.subscriptionExpiryString}
                                                                    validationMessage={errors.subscriptionExpiryString}
                                                                    marginBottom={0}
                                                                />
                                                            )}
                                                        </InputMask>
                                                    </div>
                                                </div>
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <div style={{ width: "100%" }}>
                                                            <FormField
                                                                label={t("profile.new_password")}
                                                                labelFor="newPassword"
                                                            />
                                                            <TextInput
                                                                type="password"
                                                                name="newPassword"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.newPassword}
                                                                isInvalid={!!errors.newPassword}
                                                                width="100%"
                                                            />
                                                            {errors && errors.newPassword && (
                                                                <span
                                                                    className="invalid-feedback"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    {errors.newPassword}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-column">
                                                        <div style={{ width: "100%" }}>
                                                            <FormField
                                                                label={t("profile.new_password_confirmation")}
                                                                labelFor="newPasswordCheck"
                                                            />
                                                            <TextInput
                                                                type="password"
                                                                name="newPasswordCheck"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values && values.newPasswordCheck}
                                                                isInvalid={!!errors.newPasswordCheck}
                                                                width="100%"
                                                            />
                                                            {errors && errors.newPasswordCheck && (
                                                                <span
                                                                    className="invalid-feedback"
                                                                    style={{ display: "block" }}
                                                                >
                                                                    {errors.newPasswordCheck}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex-row">
                                                    <div className="flex-column">
                                                        <FormField label="Usuário ativo?" labelFor="ativo" />
                                                        <Switch
                                                            name="ativo"
                                                            height={24}
                                                            checked={values.ativo}
                                                            onChange={handleChange}
                                                            marginLeft={10}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-row">
                                                    <Button
                                                        type="submit"
                                                        appearance="primary"
                                                        className="primary"
                                                        justifyContent="center"
                                                        disabled={Object.keys(errors).length > 0}
                                                    >
                                                        {t("save")}
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <div className="card">
                    <div>
                        <Button
                            type="button"
                            appearance="primary"
                            className="primary"
                            justifyContent="center"
                            onClick={() => {
                                setFocusedOffice(undefined);
                                setShowDetails(true);
                            }}
                        >
                            Novo escritório
                        </Button>
                    </div>
                    <Table marginTop={10}>
                        <Table.Head height={70}>
                            <Table.TextHeaderCell fontSize={16} flexBasis={100} flexShrink={0} flexGrow={0}>
                                Foto
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell fontSize={16}>
                                <div
                                    className="sortable-column"
                                    title="Clique para ordenar"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setSort("nome");
                                        setSortNome(prev => {
                                            const newSort = prev === "asc" ? "desc" : "asc";
                                            setEscritorios(prev => {
                                                const sorted = sortByString(prev, "name", newSort);
                                                profileStore.setProfiles(sorted);
                                                return sorted;
                                            });
                                            return newSort;
                                        });
                                    }}
                                    role="button"
                                >
                                    Escritório
                                    {sort === "nome" && sortNome === "desc" && (
                                        <FontAwesomeIcon icon={faChevronDown} style={{ fontWeight: "bold" }} />
                                    )}
                                    {sort === "nome" && sortNome === "asc" && (
                                        <FontAwesomeIcon icon={faChevronUp} style={{ fontWeight: "bold" }} />
                                    )}
                                </div>
                                <SearchInput
                                    placeholder="Nome do escritório"
                                    width="100%"
                                    onKeyUp={(e: any) => {
                                        e.stopPropagation();
                                        setFilter(e.target.value);
                                    }}
                                />
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell
                                fontSize={16}
                                flexBasis={100}
                                flexShrink={0}
                                flexGrow={0}
                                title="Clique para ordenar"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSort("pagamento");
                                    setSortPagamento(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        setEscritorios(prev => {
                                            const sorted = sortByDate(prev, "lastRenew", newSort);
                                            profileStore.setProfiles(sorted);
                                            return sorted;
                                        });
                                        return newSort;
                                    });
                                }}
                                role="button"
                            >
                                <div className="sortable-column">
                                    Pagamento
                                    {sort === "pagamento" && sortPagamento === "desc" && (
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            style={{ fontWeight: "bold", marginLeft: 5 }}
                                        />
                                    )}
                                    {sort === "pagamento" && sortPagamento === "asc" && (
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            style={{ fontWeight: "bold", marginLeft: 5 }}
                                        />
                                    )}
                                </div>
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell
                                fontSize={16}
                                flexBasis={100}
                                flexShrink={0}
                                flexGrow={0}
                                title="Clique para ordenar"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSort("vencimento");
                                    setSortVencimento(prev => {
                                        const newSort = prev === "asc" ? "desc" : "asc";
                                        setEscritorios(prev => {
                                            const sorted = sortByDate(prev, "subscriptionExpiry", newSort);
                                            profileStore.setProfiles(sorted);
                                            return sorted;
                                        });
                                        return newSort;
                                    });
                                }}
                                role="button"
                            >
                                <div className="sortable-column">
                                    Vencimento
                                    {sort === "vencimento" && sortVencimento === "desc" && (
                                        <FontAwesomeIcon
                                            icon={faChevronDown}
                                            style={{ fontWeight: "bold", marginLeft: 5 }}
                                        />
                                    )}
                                    {sort === "vencimento" && sortVencimento === "asc" && (
                                        <FontAwesomeIcon
                                            icon={faChevronUp}
                                            style={{ fontWeight: "bold", marginLeft: 5 }}
                                        />
                                    )}
                                </div>
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                            {escritorios.length === 0 && (
                                <Table.Row>
                                    <Table.TextCell fontSize={16}>Nenhum escritório encontrado</Table.TextCell>
                                </Table.Row>
                            )}
                            {escritorios.map(agencia => (
                                <Table.Row
                                    key={agencia._id}
                                    height={100}
                                    isSelectable
                                    onSelect={() => {
                                        setFocusedOffice(agencia as Profile);
                                        setShowDetails(true);
                                    }}
                                >
                                    <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                        <Avatar
                                            src={agencia.picture ? config.host + agencia.picture : undefined}
                                            name={agencia.name}
                                            size={70}
                                        />
                                    </Table.TextCell>
                                    <Table.TextCell fontSize={16}>{agencia.name}</Table.TextCell>
                                    <Table.TextCell fontSize={16} flexBasis={100} flexShrink={0} flexGrow={0}>
                                        {agencia.lastRenew ? formatDate(new Date(agencia.lastRenew)) : "-"}
                                    </Table.TextCell>
                                    <Table.TextCell fontSize={16} flexBasis={100} flexShrink={0} flexGrow={0}>
                                        {agencia.subscriptionExpiry
                                            ? formatDate(new Date(agencia.subscriptionExpiry))
                                            : "-"}
                                    </Table.TextCell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </>
        );
    })
);
