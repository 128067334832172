import React from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { Stores } from "../models/generic";
import { inject, observer } from "mobx-react";
import { FirstLogin } from "./FirstLogin";
import { Cadastro } from "./Cadastro";
import { Elenco } from "./Elenco";
import { Sobre } from "./Sobre";
import { Busca } from "./Busca";
import { Escritorios } from "./Escritorios";
import { Etica } from "./Etica";
import { Administracao } from "./Administracao";

export const Routes = inject("rootStore")(
    observer((props: Stores) => {
        const { sessionStore } = props.rootStore!;

        return (
            <Switch>
                <Route exact path="/">
                    {sessionStore.logged &&
                    (!sessionStore?.session?.profile?.updatedAt || !sessionStore?.session?.profile?.termsAgreedAt) ? (
                        <FirstLogin />
                    ) : sessionStore.isDirector ? (
                        <Administracao />
                    ) : (
                        <Busca />
                    )}
                </Route>
                <Route path="/busca">
                    <Busca />
                </Route>
                <Route path="/sobre">
                    <Sobre />
                </Route>
                <Route path="/codigo-etica">
                    <Etica />
                </Route>
                <Route path="/escritorios">
                    <Escritorios />
                </Route>
                <PrivateRoute path="/cadastro">
                    <Cadastro />
                </PrivateRoute>
                <PrivateRoute path="/elenco">
                    <Elenco />
                </PrivateRoute>
                <PrivateRoute path="/admin">
                    <Administracao />
                </PrivateRoute>
            </Switch>
        );
    })
);

const PrivateRoute = inject("rootStore")(
    observer(({ children, rootStore, ...rest }: RouteProps & Stores) => {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    rootStore?.sessionStore.logged ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
                }
            />
        );
    })
);
