import React, { Suspense } from "react";
import "./App.css";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "mobx-react";
import { Pane } from "evergreen-ui";

import "react-datepicker/dist/react-datepicker.css";
import { LazyLoading, Loading } from "./components/Loading";
import { RootStore } from "./stores/root.store";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { syncHistoryWithStore } from "mobx-react-router";
import "mobx-react-lite/batchingForReactDom";
import { Routes } from "./components/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const browserHistory = createBrowserHistory();

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
    return (
        <Suspense fallback={<LazyLoading />}>
            <Home />
        </Suspense>
    );
}

export function Home() {
    const rootStore = new RootStore();
    const history = syncHistoryWithStore(browserHistory, rootStore.routingStore);

    return (
        <Provider rootStore={rootStore}>
            <Router history={history}>
                <Loading />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <Pane margin={20}>
                    <Header />
                    <div className="content">
                        <Routes />
                    </div>
                    <Footer />
                </Pane>
            </Router>
        </Provider>
    );
}
