import React from "react";
import { Heading } from "evergreen-ui";

export function Footer() {
    return (
        <div className="footer">
            <Heading is="h3" fontSize={14} color="#0d0d0d">
                &copy; 2024 - An&ecirc;mona
            </Heading>
        </div>
    );
}
