import { get, post, postMultiPart, put } from "./request.service";
import { config } from "../utils/constants";
import { ProfileUpdateRequest, Agency, NewProfileRequest, Profile, Session } from "../models";

export async function getProfileDetails(session?: Session): Promise<Profile> {
    return get(`${config.url}/profile/me`, session);
}

export async function getProfiles(session?: Session): Promise<Profile[]> {
    return get(`${config.url}/profile/all`, session);
}

export async function agreeToTerms(body: any): Promise<Profile> {
    return put(`${config.url}/profile/agree-terms`, body);
}

export async function forgotPassword(fiscalCode: string): Promise<Pick<Profile, "email">> {
    return post(`${config.url}/profile/forgot-password`, { fiscalCode });
}

export async function finalizeEditing(): Promise<Profile> {
    return get(`${config.url}/profile/finalize-editing`);
}

export function updateProfile(body: Partial<Profile>, picture?: File | null): Promise<Profile> {
    const formData = new FormData();
    formData.append("profileRequest", JSON.stringify(body));
    if (picture) {
        formData.append("picture", picture);
    }
    return postMultiPart(`${config.url}/profile/update`, formData);
}

export function changePassword(password: string): Promise<Profile> {
    return post(`${config.url}/profile/change-password`, { password });
}

export async function getAlAgencies(): Promise<Agency[]> {
    return get(`${config.url}/agencies`);
}

export function newProfile(body: NewProfileRequest): Promise<Profile> {
    return post(`${config.url}/profile/new`, body);
}

export function updateProfileAdmin(id: string, body: ProfileUpdateRequest): Promise<Profile> {
    return post(`${config.url}/profile/${id}/update`, body);
}
