import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Stores } from "../models/generic";
import { useTranslation } from "react-i18next";
import { Avatar, Button, Dialog, FilePicker, SearchInput, Table, TextInputField } from "evergreen-ui";
import { config } from "../utils/constants";
import { deleteArtist, listArtists, newArtist, updateArtist } from "../services/artist.service";
import { toast } from "react-toastify";
import { Artist } from "../models";
import { Formik } from "formik";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { getCroppedImg } from "../utils/utils";

export const Elenco = inject("rootStore")(
    observer((props: Stores) => {
        const { t } = useTranslation();
        const [filter, setFilter] = useState("");
        const { artistStore, loadingStore } = props.rootStore!;
        const [artists, setArtists] = useState<Artist[]>([]);
        const [showDetails, setShowDetails] = useState(false);
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [focusedArtist, setFocusedArtist] = useState<Artist | undefined>(undefined);

        /**
         * CROP properties
         */
        const [image, setImage] = useState<Blob | undefined>(undefined);
        const [imageBase64, setImageBase64] = useState<string | undefined>(undefined);
        const [showCrop, setShowCrop] = useState(false);
        const [crop, setCrop] = useState<Crop>({ x: 25, y: 0, unit: "%", aspect: 1, width: 50 });
        const [width, setWidth] = useState(0);
        const [height, setHeight] = useState(0);
        const [, setFileName] = useState("123");

        const reader = new FileReader();
        reader.addEventListener(
            "load",
            function () {
                // convert image file to base64 string
                setImageBase64(reader.result as string);
            },
            false
        );
        /**
         * END
         */

        useEffect(() => {
            if (artistStore.artists.length === 0) {
                loadingStore.triggerLoading();
                listArtists()
                    .then(artists => {
                        artistStore.setArtists(artists);
                        loadingStore.stopLoading();
                    })
                    .catch(() => {
                        loadingStore.stopLoading();
                        toast.error("Erro ao listar artistas");
                    });
            }
        }, [artistStore, loadingStore]);

        useEffect(() => {
            if (filter) {
                setArtists(artistStore.filter(filter));
            } else {
                setArtists(artistStore.artists);
            }
        }, [artistStore, artistStore.artists, filter]);

        const onSubmit = async (values: Partial<Artist>) => {
            setShowDetails(false);
            loadingStore.triggerLoading();
            if (focusedArtist) {
                updateArtist(focusedArtist?._id!, values, image)
                    .then(artist => {
                        artistStore.updateArtist(artist);
                        loadingStore.stopLoading();
                        setFocusedArtist(undefined);
                        toast.success("Dados do artista atualizado com sucesso");
                    })
                    .catch(() => {
                        setShowDetails(true);
                        loadingStore.stopLoading();
                        toast.error("Erro ao atualizar dados do artista");
                    });
            } else {
                newArtist(values, image)
                    .then(artist => {
                        artistStore.addArtist(artist);
                        loadingStore.stopLoading();
                        setFocusedArtist(undefined);
                        toast.success("Dados do artista salvos com sucesso");
                    })
                    .catch(() => {
                        setShowDetails(true);
                        loadingStore.stopLoading();
                        toast.error("Erro ao salvar dados do artista");
                    });
            }
        };

        const onDelete = () => {
            if (focusedArtist && focusedArtist._id) {
                loadingStore.triggerLoading();
                setShowConfirmation(false);
                setShowDetails(false);
                deleteArtist(focusedArtist._id)
                    .then(() => {
                        artistStore.removeArtistById(focusedArtist._id!);
                        loadingStore.stopLoading();
                        setFocusedArtist(undefined);
                        toast.success("Artista removido com sucesso");
                    })
                    .catch(() => {
                        setShowDetails(true);
                        loadingStore.stopLoading();
                        toast.error("Erro ao remover artista");
                    });
            }
        };

        return (
            <>
                {showCrop && (
                    <Dialog
                        isShown={true}
                        title="Ajuste de tamanho da imagem"
                        onCloseComplete={() => {
                            setShowCrop(false);
                            setImage(undefined);
                            setImageBase64(undefined);
                            setFileName("");
                        }}
                        hasFooter={false}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                            ref={e => {
                                if (e?.clientWidth && e.clientHeight) {
                                    setWidth(e.clientWidth);
                                    setHeight(e.clientHeight);
                                }
                            }}
                        >
                            {imageBase64 && (
                                <ReactCrop
                                    src={imageBase64}
                                    crop={crop}
                                    circularCrop
                                    onChange={crop => {
                                        setCrop(crop);
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            <Button
                                type="submit"
                                appearance="primary"
                                className="primary"
                                justifyContent="center"
                                onClick={async () => {
                                    try {
                                        const { blob, base64 } = await getCroppedImg(imageBase64!, crop, width, height);
                                        if (blob && base64) {
                                            setImage(blob);
                                            setImageBase64(base64);
                                            setShowCrop(false);
                                        } else {
                                            toast.error("Erro ao ajustar tamanho da imagem");
                                        }
                                    } catch (e) {
                                        toast.error("Erro ao ajustar tamanho da imagem");
                                    }
                                }}
                                style={{
                                    marginTop: 5
                                }}
                            >
                                Recortar
                            </Button>
                        </div>
                    </Dialog>
                )}
                <Dialog
                    isShown={showDetails}
                    title={focusedArtist ? "Detalhes do artista" : "Novo artista"}
                    onCloseComplete={() => {
                        setShowDetails(false);
                        setImage(undefined);
                        setImageBase64(undefined);
                    }}
                    hasFooter={false}
                >
                    <Formik
                        initialValues={{
                            artisticName: focusedArtist?.artisticName,
                            website: focusedArtist?.website
                        }}
                        enableReinitialize
                        validate={values => {
                            // same as above, but feel free to move this into a class method now.
                            let errors: Partial<Artist> = {};
                            if (!values.artisticName) {
                                errors.artisticName = t("form.errors.required");
                            }
                            return errors;
                        }}
                        onSubmit={onSubmit}
                    >
                        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="flex-table">
                                    <div className="flex-row">
                                        <div className="flex-column">
                                            <div className="flex-table">
                                                {focusedArtist && (
                                                    <div
                                                        className="flex-row"
                                                        style={{ flexGrow: 0, justifyContent: "center" }}
                                                    >
                                                        <Avatar
                                                            src={
                                                                imageBase64 ??
                                                                (focusedArtist?.picture
                                                                    ? config.host + focusedArtist.picture
                                                                    : undefined)
                                                            }
                                                            name={focusedArtist?.artisticName}
                                                            size={250}
                                                        />
                                                    </div>
                                                )}
                                                {!focusedArtist && imageBase64 && (
                                                    <div
                                                        className="flex-row"
                                                        style={{ flexGrow: 0, justifyContent: "center" }}
                                                    >
                                                        <Avatar src={imageBase64} name="" size={250} />
                                                    </div>
                                                )}
                                                <div className="flex-row" style={{ flexGrow: 0 }}>
                                                    <FilePicker
                                                        multiple={false}
                                                        width="100%"
                                                        marginTop={16}
                                                        marginBottom={24}
                                                        accept="image/*"
                                                        onChange={event => {
                                                            if (event && event.length > 0) {
                                                                reader.readAsDataURL(event[0]);
                                                                setShowCrop(true);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="flex-row">
                                                    <TextInputField
                                                        type="text"
                                                        name="artisticName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.artisticName}
                                                        label="Nome:"
                                                        isInvalid={!!errors.artisticName}
                                                        validationMessage={errors.artisticName}
                                                        width="100%"
                                                    />
                                                </div>
                                                <div className="flex-row">
                                                    <TextInputField
                                                        type="text"
                                                        name="website"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values && values.website}
                                                        label="Página:"
                                                        isInvalid={!!errors.website}
                                                        validationMessage={errors.website}
                                                        width="100%"
                                                    />
                                                </div>
                                                <div className="flex-row">
                                                    <Button
                                                        type="submit"
                                                        appearance="primary"
                                                        className="primary"
                                                        justifyContent="center"
                                                        disabled={focusedArtist ? false : !image}
                                                    >
                                                        {t("save")}
                                                    </Button>
                                                    {focusedArtist && (
                                                        <Button
                                                            appearance="primary"
                                                            intent="danger"
                                                            onClick={(e: any) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setShowDetails(false);
                                                                setShowConfirmation(true);
                                                            }}
                                                            style={{ marginLeft: "10px" }}
                                                        >
                                                            Remover artista
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Dialog>
                <Dialog
                    isShown={showConfirmation}
                    title="Confirmação de remoção"
                    onCloseComplete={() => {
                        setShowConfirmation(false);
                        setShowDetails(true);
                    }}
                    onConfirm={onDelete}
                    confirmLabel="Sim"
                    cancelLabel="Não"
                >
                    Deseja realmente remover este artista?
                </Dialog>
                <div className="card">
                    <div>
                        <Button
                            type="button"
                            appearance="primary"
                            className="primary"
                            justifyContent="center"
                            onClick={() => {
                                setFocusedArtist(undefined);
                                setShowDetails(true);
                            }}
                        >
                            Novo artista
                        </Button>
                    </div>
                    <Table marginTop={10}>
                        <Table.Head height={70}>
                            <Table.TextHeaderCell fontSize={16} flexBasis={100} flexShrink={0} flexGrow={0}>
                                Foto
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell fontSize={16}>
                                Nome do artista
                                <br />
                                <SearchInput
                                    placeholder="Nome do artista"
                                    width="100%"
                                    onKeyUp={(e: any) => {
                                        setFilter(e.target.value);
                                    }}
                                />
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                            {artists.length === 0 && (
                                <Table.Row>
                                    <Table.TextCell fontSize={16}>Nenhum artista encontrado</Table.TextCell>
                                </Table.Row>
                            )}
                            {artists.map(artist => (
                                <Table.Row
                                    key={artist._id}
                                    height={100}
                                    isSelectable
                                    onSelect={() => {
                                        setFocusedArtist(artist);
                                        setShowDetails(true);
                                    }}
                                >
                                    <Table.TextCell flexBasis={100} flexShrink={0} flexGrow={0}>
                                        <Avatar
                                            src={artist.picture ? config.host + artist.picture : undefined}
                                            name={artist.artisticName}
                                            size={70}
                                        />
                                    </Table.TextCell>
                                    <Table.TextCell fontSize={16}>{artist.artisticName}</Table.TextCell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </>
        );
    })
);
